<template>
  <div class="companyprofile">
    <div class="bg">
      <img src="@imgs/gsjjbg.png" width="341px" height="93px" alt="" class="cpzx" />
    </div>
    <div class="companybrief">
      <div class="title">
        <div class="raio"></div>
        <span>公司简介</span>
        <div class="raio"></div>
      </div>
      <div class="content">
        <p>【名优世汇】是四川名优世汇医药有限公司旗下国家药监局批准的正规药品采购、批发、销售网上药品交易电子商务平台，主要经营普药、OTC高毛利、医疗器械、中药饮片；300多人的团队为您的采购和销售，保驾护航，为您提供最有价值的医药服务；平台保证您的货款安全和药品质量。</p>
        <!-- <p>公司主营业务为药品批发与零售，经营药品品规12000余个，各类药品配送车辆50余辆，销售网络覆盖四川省各地州及邻近的山东、广东、贵州、四川等省的部份地区。自2017年成立至2022年累计销售总额超74亿元，2022年销售总额24.7亿余元。在“积极主动、勤奋进取、诚信敬爱、专业协作、极致创新”核心价值观激励下，公司经多年持续向好发展，已进入四川省药品批发与零售头部企业行列，在省内享有较高的知名度。</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      proList: [
        { id: 0, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 1, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 2, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 3, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 4, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 5, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 6, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 7, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 8, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 9, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 10, img: require("@imgs/pricer.png"), title: "西洋参" },
        { id: 11, img: require("@imgs/pricer.png"), title: "西洋参" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.companyprofile {
  .companybrief {
    margin-top: 30px;

    .title {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #fe2a1e;
      display: flex;
      justify-content: center;
      align-items: center;

      .raio {
        width: 6px;
        height: 6px;
        background: #fe2a1e;
        border-radius: 50%;
        display: inline-block;
      }

      span {
        margin: 0 7px;
      }
    }

    .content {
      width: 1187px;
      margin: 0 auto;
      margin-bottom: 30px;
      text-align: left;
      font-size: 18px;
      font-family: DengXian;
      color: #666666;
    }
  }
}
</style>